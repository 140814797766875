<template>
  <div class="page">
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="list-content"
          v-for="(item, index) in listData"
          :key="index"
          @click="toRoute(item)"
        >
          <div class="list-sidebar">
            <img :src="item.activityPicture" alt="" />
          </div>
          <div class="list-wrapper">
            <div class="wrap-title">{{ item.activityName || "--" }}</div>
            <div class="wrap-content">
              <div class="wrap-content_time">
                报名开始时间：{{ item.applyStartDate }}
              </div>
              <div class="wrap-content_time">
                活动开始时间：{{ item.startDate }}
              </div>
            </div>
          </div>
        </div>
      </v-list>
    </v-refresh>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getActivityListUrl } from "./api.js";

export default {
  name: "activityList",
  data() {
    return {
      isLoading: false,
      finished: false,
      finishedText: "没有更多了",
      listData: [],
      curPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "houseId", "communityId"]),
  },
  methods: {
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getActivityListUrl, {
          params: {
            activityStatus: 2,
            userId: this.userId,
            houseId: this.houseId || this.communityId || undefined,
            tenantId: this.tenantId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            this.listData = [];
            return;
          }
          this.finishedText = "没有更多了";
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.activityId);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.activityId) || this.listData.push(item);
              });
            }
            this.curPage++;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    toRoute(item) {
      this.$router.push({
        name: "activityUserList",
        query: {
          activityId: item.activityId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 32px 32px 0 32px;
  .list-content {
    display: flex;
    &:not(:first-child) {
      margin: 32px 0px 0px 0px;
    }
    padding: 24px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 10%);
    .list-sidebar {
      width: 160px;
      height: 160px;
      min-width: 160px;
      margin-right: 20px;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .list-wrapper {
      flex: 1;
      width: calc(100% - 180px);
      .wrap-title {
        font-size: 36px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .wrap-content {
        font-size: 28px;
        color: #999;
        .wrap-content_time {
          margin-top: 18px;
        }
      }
    }
  }
}
</style>
